import React from 'react'
import abimg from "../image2/img4/Website-CTA-Bannner-2048x563.jpg"
import Mission from './Mission'
import Value from './Value'
import Cards from './Cards'

function About() {
  return (
    <>
      {/* <div className='aboutimg'>
        <h1 className='text-white text-center pt-5'>ABOUT US</h1>
      </div> */}
      <img src="/img/about/dreamup-aboutus1.jpg" style={{height:"400px", width:"100%"}} alt="image" />

      <Mission />
      <Value />
      <Cards />

      <div className='container mt-5'>
        <img src={abimg} alt='img' width={1140} height={400} />
      </div>
    </>
  )
}

export default About
