import React from 'react'
import citizen from "../image2/citizenship.png";
import inform from "../image2/information.png";
import support from "../image2/relatives_support.png";
import guidance from "../image2/property-guidance.png";
import child from "../image2/child_social_welfcare.png";
import bank from "../image2/banking_account.png";
function Unique() {
  return (
    <>
      <div className='unique-main row'>
        <div className='unique-1 col-xl-6 col-sm-12'>
          <div className='uniimg-1 '>
            <div className='uniimg-11'>
              <img src={citizen} alt='img' id='img2' />
              <p>Complex support for<br />
                acquiring citizenship</p>
            </div>
            <div className='uniimg-12'>
              <img src={inform} alt='img' id='img2' />
              <p>Practical information<br />
                on amenities</p>
            </div>
          </div>
          <div className='uniimg-2 '>
            <div className='uniimg-21'>
              <img src={support} alt='img' id='img2' />
              <p>Relatives suppert &<br />
                guarantee their <br />
                parmanent residence</p>
            </div>
            <div className='uniimg-22'>
              <img src={guidance} alt='img' id='img2' />
              <p>Guidance for renting<br />
                your own property</p>
            </div>
          </div>
          <div className='uniimg-3 '>
            <div className='uniimg-31'>
              <img src={child} alt='img' id='img2' />
              <p>Child & social<br />
                welfcare schemes <br />
                information</p>
            </div>
            <div className='uniimg-32'>
              <img src={bank} alt='img' id='img2' />
              <p>Assistance & advice<br />
                on opening a bank<br />
                account</p>
            </div>
          </div>
        </div>
        <div className='unique-2 col-xl-6 col-sm-12'>
          <h1 className='text-warning mb-5'>WHATS  MAKES US UNIQUE</h1>
          <p className='mb-5'>We do not only want you to reach your new country safely, but also to
            have comprehensive support throughout your whole stay there, what
            includes i.e.:</p>

          <i class="bi bi-arrow-right" ></i><span class='span1'> Assistance in applying for permanent residence card</span><br />
          <i class="bi bi-arrow-right" ></i><span className='span1'>Complex support for acquiring citizenship </span><br />
          <i class="bi bi-arrow-right" ></i><span className='span1'>Practical information on where you will reside, such as public transport,
            services, entertainment facilities</span><br />
          <i class="bi bi-arrow-right" ></i><span className='span1'>Support your relatives' arrival and guarantee their permanent residence <br /></span>
          <i class="bi bi-arrow-right" ></i><span className='span1'>Information on child and social welfare schemes </span><br />
          <i class="bi bi-arrow-right" ></i><span className='span1'>Guidance for renting your own property </span><br />
          <i class="bi bi-arrow-right" ></i><span className='span1'>Guidance for admission in language or driving course</span> <br />
          <i class="bi bi-arrow-right" ></i><span className='span1'>Assistance and advice on opening a bank account </span><br />

          <p className='mt-5'>DreamUp Education and Immigration Group aims to support you expeditiously for you to have a higher
            quality of life through our various opportunities in several destinations
            across Europe and Canada. </p>
          <p className='mt-5 mb-5'>We are there for you from the beginning of the process till the time you
            land and start work in your dream destination country.  DreamUp Education and Immigration Group - We
            are for people!</p>
          <button type="button" class="btn btn-warning center">Apply Now</button>
        </div>
      </div>
    </>
  )
}

export default Unique
