import React from 'react'
import image from "../image2/img3/aa.png";
import image2 from "../image2/img3/aaa.png";
import image3 from "../image2/img3/aaaa.png";
// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/pagination';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Review() {
  return (
    <>
      <h1 className='text-center mt-4 mb-4 text-warning'>Reviews</h1>
      <div className='main-review  mb-5 row'>
        <Swiper
          slidesPerView={3}
          centeredSlides={true}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiperr"
        >
          <SwiperSlide>
            <div className='review-3 border-1px rounded-5 h-200 col-xl-4 col-sm-12' style={{ height: "300px", width: "300px", paddingLeft: "10px", paddingRight: "10px" }} >
              <div className='d-flex justify-content-between'>
                <img src={image3} alt='image' height={40} />
                <b className='mt-2'>Rahul Aryan</b>
                
              </div>
              <i class="bi bi-star-fill" style={{ color: "yellow" }} ></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: "yellow" }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <p className='mt-2'>Thank u DreamUp education and Immigration for my 
                poland visa <br /> application.I am now here in poland to<br />
                make my European dream happened.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-2 border-1px  me-5 rounded-5 col-xl-4 col-sm-12' style={{ height: "300px", width: "300px", paddingLeft: "10px", paddingRight: "10px" }}>
              <div className='d-flex justify-content-between'>
                <img src={image2} alt='image' height={40} />
                <b className='mt-2 '>Azam khan</b>
                
              </div>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}>
                </i><i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <p className='mt-3'>Thankful for DreamUp education and Immigration 
                assisting me in visa processing to Poland. Got lucky to have visa 
                appearance. And now I am here in Poland. and Thank you  DreamUp education 
                and Immigration company.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-1 border-1px w-30 me-5 rounded-5 col-xl-4 col-sm-12' style={{ height: "300px", width: "300px", paddingLeft: "10px", paddingRight: "10px" }}>
              <div className='d-flex justify-content-between'>
                <img src={image} alt='image' height={40} />
                <b className='mt-2'>Naman Aryan</b>
                
              </div>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i><i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <p className='mt-2'>I would like to thank DreamUp education and Immigration
                company for all the help on my visa application process to Poland. 
                visa appearance and now i flew here in Poland. Thank you.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-3 border-1px rounded-5' style={{ height: "300px", width: "300px", paddingLeft: "10px", paddingRight: "10px" }}>
              <div className='d-flex justify-content-between'>
                <img src={image3} alt='image' height={40} />
                <b className='mt-2'>Vipin Kumar</b>
                
              </div>
              <i class="bi bi-star-fill" style={{ color: "yellow" }} >
                </i><i class="bi bi-star-fill" style={{ color: 'yellow' }}>
                  </i><i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: "yellow" }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <p className='mt-3'>Thank u DreamUp education and Immigration for my 
                poland visa application.I am now here in poland to<br />
                make my European dream happened.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-2 border-1px  me-5 rounded-5' style={{ height: "300px", width: "300px", paddingLeft: "10px", paddingRight: "10px" }}>
              <div className='d-flex justify-content-between'>
                <img src="" alt='image' height={40} />
                <b className='mt-2 '>Manoj Kumar</b>
                
              </div>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <p className='mt-3'>Thankful for DreamUp education and Immigration 
                assisting me in visa processing to Poland. Got lucky to have visa 
                appearanc appointment for 1 month waiting. And now I am here in Poland. 
                Thank you  DreamUp company.</p>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='review-1 border-1px w-30 me-5 rounded-5' style={{ height: "300px", width: "300px", paddingLeft: "10px", paddingRight: "10px" }}>
              <div className='d-flex justify-content-between'>
                <img src="" alt='image' height={40} />
                <b className='mt-2'>Ankit</b>
              </div>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <i class="bi bi-star-fill" style={{ color: "yellow" }} ></i>
              <i class="bi bi-star-fill" style={{ color: 'yellow' }}></i>
              <p className='mt-3'>I would like to thank DreamUp education and Immigration
                company for all the help on my visa application process to Poland. 
                visa appearance and now i flew here in Poland. process. Thank you 
                DreamUp.</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </>
  )
}

export default Review
