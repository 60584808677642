import React from 'react'
import aboutimng from "../image2/img4/aboutimg.jpg";
function Mission() {
  return (
    <>
       <div className='container d-flex mt-5'>
           <div className=''>
               <h1 className='text-warning'>OUR MISSION</h1>
               <p>DreamUp Education and Immigration is responsible for the provision of easy international migration<br/>
                services to Europe and Canada, from the point of contact and application process<br/>
                 to the arrival. </p>

                 <h3 className='text-warning'>GLOBAL PRESENCE</h3><br/>
                 <p>We have offices globally present in the UAE (Dubai, Abu Dhabi), Malta, Canada <br/>
                 and Poland to assist the successful accomplishment of your new career and life to <br/>
                 your desired destination.</p>

                 <p>We are for people!</p>

                 <p className='text-warning'>Get started today <i class="bi bi-arrow-right"></i></p>
           </div>
           <div className=''>
              <img src="/img/about/dreamup-aboutus2.jpg"  alt='img' />
           </div>
       </div>
       <div>
       </div>
    </>
  )
}

export default Mission
