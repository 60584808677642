import React from 'react'

function Center() {
  return (
    <>
      <div className='d-flex mt-5'>
        <div style={{ backgroundColor: "yellow", width: "50%", height: "400px" }} className='pt-5 ps-5'>
          <h1>DreamUP Education and Immigration</h1> <br />
          <b><p>Below Lotus Hospital,</p>
            <p>Sanoli Road, Panipat, Haryana,</p>
            <p>India-132103</p></b>
        </div>
        <div style={{ width: "50%", height: "400px" }}>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.4439012585567!2d76.97414877420265!3d29.386567749443074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dda5a88e69ffd%3A0x74adc5c48d693675!2sLotus%20Hospital!5e0!3m2!1sen!2sin!4v1719985637240!5m2!1sen!2sin"
            style={{ width: "100%", height: "400px", style: "border:0;", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
        </div>
      </div>
    </>
  )
}

export default Center
