import React from 'react'
import { Link } from 'react-router-dom'

const Achievements = () => {
  return (
    <>
      {/* Section Start */}
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80 mt-3">
        <div className="container">
        <div className="sec-title mb-30">
        <div className="sub-text style2">Our Achiements.</div>
          <h2 className="title pb-38 tcc">
            Our Achiements...
          </h2>
          <div className="desc pb-35">
            Germany is increasing in popularity among students looking to study abroad,
            and for all its good reasons! The advantages of studying in Germany are numerous.
            The country's rich tradition, cultural diversity, bright and pleasant lifestyle,
            and globally recognized degrees guaranteeing exceptional employ-ability have added to its growing admiration.
            According to a survey, Indians make up the second-largest international student population attending German universities.
            <Link>learn more...</Link>
          </div>
          </div>
        </div>
      </div>
      {/* Section End */}
      <p></p>
      <img src="img/dreamup-achievement.jpg" style={{ width: "100%", height: "400px" }} alt='germany image' />

      {/* Process Section Start */}
      <div className="rs-process style3 gray-color pt-120 pb-120 md-pt-75 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 pl-60 md-pl-15">
              <div className="contact-wrap">
                <div className="sec-title mb-30">
                  <div className="sub-text style2">We working.</div>
                  <h2 className="title title4  pb-20">
                    How assist you.
                  </h2>
                  <p></p>
                  <div className="desc ">
                    Germany's central and southern regions have forested hills and mountains cut through by the Danube,
                    Main, and Rhine river valleys. In the north, the landscape flattens out to a wide plain that stretches
                    to the North Sea. Between these extremes, Germany is a country of incredible variety.
                    Germany's location at the heart of Europe has shaped its history both for good and bad.
                    It borders nine neighbors, more than any other European country.
                    Germany's largest wooded area, and its most famous, is in the southwest near the Swiss border.
                    This is the Black Forest, a mountainous region full of pines and fir trees.
                    This forest contains the source of the Danube, one of Europe's longest rivers.
                  </div>
                  <p></p>
                  <div className="btn-part mt-40">
                    <a className="readon learn-more contact-us" href="">Learn More</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 pl-35 md-pt-40 md-pl-15">
              <div className="row">
                <div className="col-md-6 mb-20">
                  <div className="rs-addon-number">
                    <div className="number-text">
                      <div className="number-area" style={{color: "#fac227"}}>
                        01
                      </div>
                      <div className="number-title">
                        <h3 className="title">Visa Consultation</h3>
                      </div>
                      <p className="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh theo lacus egestas.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-20">
                  <div className="rs-addon-number">
                    <div className="number-text">
                      <div className="number-area " style={{color: "#fac227"}}>
                        02
                      </div>
                      <div className="number-title">
                        <h3 className="title">Immigration</h3>
                      </div>
                      <p className="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh theo lacus egestas.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-20">
                  <div className="rs-addon-number">
                    <div className="number-text">
                      <div className="number-area" style={{color: "#fac227"}}>
                        03
                      </div>
                      <div className="number-title">
                        <h3 className="title">Education</h3>
                      </div>
                      <p className="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh theo lacus egestas.</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-20">
                  <div className="rs-addon-number">
                    <div className="number-text">
                      <div className="number-area" style={{color: "#fac227"}}>
                        04
                      </div>
                      <div className="number-title">
                        <h3 className="title">Job Consultancy</h3>
                      </div>
                      <p className="number-txt">Quisque placerat vitae lacus ut scelerisque. Fusce luctus odio ac nibh theo lacus egestas.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Process Section End */}
    </>
  )
}

export default Achievements