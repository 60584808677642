import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
  return (
    <>
      <div className='bg-dark p-3 row'>
        <div className='col-xl-5 col-sm-12  col-md-4'>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={faPhone} spin spinReverse style={{ color: "#FACB08" }} /><b className='text-white'> +91 8533929212</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={faEnvelope} spin spinReverse style={{ color: "#FACB08" }} /><spam className='text-white'>dreamupimmigration@gmail.com</spam>
        </div>
        <div className='col-xl-3 col-sm-12 logo' >
          <Link to="" target="_blank"> <i class="bi bi-facebook" style={{ color: "#FACB08" }}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="https://www.instagram.com/dreamup_education" target="_blank"> <i class="bi bi-instagram" style={{ color: "#FACB08" }}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="https://www.youtube.com/@DreamupEducation" target="_blank"> <i class="bi bi-youtube" style={{ color: "#FACB08" }}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="" target="_blank"> <i class="bi bi-twitter" style={{ color: "#FACB08" }}></i></Link>&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="https://www.linkedin.com/in/dreamupeducation" target="_blank"> <i class="bi bi-linkedin" style={{ color: "#FACB08" }}></i></Link>
        </div>
        <div className='col-xl-4 col-sm-12  col-md-4'>
          <button className='bg-warning rounded'>Network Partner</button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Link to="/login" style={{color: "black"}} className='bg-warning rounded '> ~ <b>  LOGIN  </b> ~ </Link> 
        </div>
      </div>
      <nav className="navbar navbar-expand-lg bg-body-warning">
        <div className="container-fluid ms-3 ">
          <Link className="navbar-brand me-5" to="/"><img src="img/dreamup-logo.png" alt='Logo-img' style={{ width: "130px", height: "39px" }} /></Link>
          
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link active fw-bold " aria-current="page" to="/">HOME</Link>
              </li>
              <li class="nav-item dropdown fw-bold ">
                <a class="nav-link dropdown-toggle active fw-bold " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  CAREER DESTINATION
                </a>
                <ul className="dropdown-menu">
                  <li><Link to={""}><img src="img/menuimage/menu-img.jpeg" alt="uk"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img2.jpeg" alt="canada"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img3.jpeg" alt="australia"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img4.jpeg" alt="europe"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img5.jpeg" alt="germany"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img6.jpeg" alt="ireland"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img7.jpeg" alt="nuewzealand"></img></Link> </li>
                  <li><Link to={""}><img src="img/menuimage/menu-img8.jpeg" alt="singapore"></img></Link> </li>

                </ul>
              </li>
              <li class="nav-item dropdown fw-bold ">
                <a class="nav-link dropdown-toggle active fw-bold " href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                ABOUT US
                </a>
                <ul className="dropdown-menu">
                  <li><Link className='nav-link active fw-bold' to={"/about"}> ABOUT US </Link> </li>
                  <li><Link className='nav-link active fw-bold' to={"/yesgermany"}> YES GERMANY </Link> </li>
                  <li><Link className='nav-link active fw-bold' to={"/director"}> DIRECTOR </Link> </li>
                  <li><Link className='nav-link active fw-bold' to={"/achievement"}> OUR Achievement</Link> </li>
                  
                </ul>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold " aria-current="page" to="/certificate">CERTIFICATE</Link>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link active fw-bold " aria-current="page" to="/gallery">GALLERY</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold " aria-current="page" to="/workvisa">WORK VISA</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold " aria-current="page" to="/career">CAREER</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link active fw-bold " aria-current="page" to="contacter">CONTACT US</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Navbar
