import React from 'react'
const Hpwg = () => {
  return (
    <>
      <div className='pwg-main '>
        <h1 className='text-center pt-4 pb-4 mb-2' style={{ color: 'yellow' }}>DreamUp in Number</h1>

        <div className=' pwg-main-1 pb-5 mt-5 row'>
          <div className='pwg-1  col-xl-3 col-sm-12' >
            <i class="bi bi-people-fill" style={{ color: "yellow", fontSize: "100px", margin: "20px" }}></i>
            <h1 className='text-white '>1,000+</h1>
            <h4 className='text-white'>EMPLOYEES</h4>
          </div>

          <div className='pwg-2   col-xl-3 col-sm-12'>
            <i class="bi bi-geo-alt" style={{ color: "yellow", fontSize: "100px", margin: "20px" }}></i>
            <h1 className='text-white '>20+</h1>
            <h4 className='text-white'>LOCATION</h4>
          </div>

          <div className='pwg-3   col-xl-3 col-sm-12'>
            <i class="bi bi-stopwatch" style={{ color: "yellow", fontSize: "100px", margin: "40px" }}></i>
            <h1 className='text-white '>24</h1>
            <h4 className='text-white'>HOURS SERVICES</h4>
          </div>
          <div className='pwg-4   col-xl-3 col-sm-12'>
            <i class="bi bi-globe" style={{ color: "yellow", fontSize: "100px", margin: "90px" }}></i>
            <h1 className='text-white '>100+</h1>
            <h4 className='text-white'>POSITIONS TO APPLY FOR</h4>
          </div>
        </div>

      </div>
    </>
  )
}

export default Hpwg
