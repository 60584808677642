import React from 'react';
import Contact from './Contact';
import AboutUs from './AboutUs';
import Packages from './Packages';
import Unique from './Unique';
import Hpwg from './Hpwg';
import Review from './Review';
import Faq from './Faq';
import Services from './Services';
// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import Safety from './Safety';
import Workin from './Workin';

function Home() {
  return (
    <>
      <div className='container'>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide.jpeg" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide1.jpg" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide2.jpg" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide3.png" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide4.png" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide5.jpg" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide6.png" alt="slide img" id="slimg" />
          </SwiperSlide>
          <SwiperSlide>
            <img src="img/slide-img/dreamup-slide7.jpg" alt="slide img" id="slimg" />
          </SwiperSlide>
        </Swiper> 
        </div>
      <Safety />
      <AboutUs />
      <div className='container-fluid mt-4 ml-5'>
        <img src="/img/dreamup-home-img.jpg" alt="image" style={{ width: "1225px", height: "600px" }}></img>
      </div>
      <Packages />
      <Unique />
      <Hpwg />
      <Workin />
      <div className='container-fluid mt-4 ml-5'>
        <img src="/img/dreamup-home-img1.png" alt="image" style={{ width: "1225px", height: "600px" }}></img>
      </div>
      <Faq />
      <Review />
      <Services />
      <Contact />
    </>
  )
}

export default Home
