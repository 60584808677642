import React from 'react'
import tcimg from "../image2/img4/Website-CTA-Bannner-2048x563.jpg"
import { Link } from 'react-router-dom'

function Contact() {
  return (
    <>
      <div className='main-div-touch mt-4 mt-5 row' style={{backgroundColor:"#f7f7eb", borderRadius:"20px"}}>
        <div className='touch-1 me-4 col-xl-6' >
          <h1 className='text-warning'>GET IN TOUCH</h1>
          <p className='text-black'>Please fill out the contact form. Our consultant will give you a call with all
            the necessary information needed for Your travel to Europe and Canada.</p>
          <p>We offer you free consultancy to discuss your predispositions, qualifications and
            expectations so that together we can choose the right career path and destination
            for you. We will answer all your questions and resolve any doubts.</p>
        </div>
        <div className='touch-2 col-xl-6' >
          <center> <h1 className='text-warning'>GET IN TOUCH</h1></center>
          <p>Please fill out the contact form. Our consultant will give you a call with all the necessary<br />
            information needed for Your travel to Europe and Canada.</p>
          <hr />
          {/* <form>
            <div class="mb-3">
              <input type="text" placeholder='First Name...' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div class="mb-3">
              <input type="text" placeholder='Last Name...' class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            </div>
            <div class="mb-3">
              <input type="mail" placeholder='E-mail' class="form-control" id="exampleInputPassword1" />
            </div>
            <div class="mb-3">
              <input type="password" placeholder='password' class="form-control" id="exampleInputPassword1" />
            </div>
            <Link  class="btn btn-warning center">Submit</Link>
          </form> */}
          <Link to="/contacter" class="btn btn-warning center">Call to Action</Link>
        </div>
      </div>
      <div className='container w-80 mt-5'>
        <img src={tcimg} alt='img' width={1300} height={400} className='img-thumbnail' />
      </div>
    </>
  )
}

export default Contact
