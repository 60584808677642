import React from 'react'
import poland from "../image/Group-110.png";
import malta from "../image/Group-1101.png";
import czech from "../image/Group-1000004662-1.png";
import polands from "../image/Group-111.png";
import canada from "../image/Group-1102.png";
import uk from "../image/dreamup-uk.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import "./workvisa.css"

function Packages() {
  return (
    <>
      <div className="package-main bg-secondary-subtle pt-3 ">
        <h1 className='text-center'>CHOOSE YOUR PACKAGE</h1>
        <p className='text-center'>To start your journey, Please select the Package that best suits you</p>
        <div class="package-main2 ">
          <div className='package-sub1 col-xl-4 col-sm-12'>
            <img src={poland} alt='poland' />
            <div className='package-sub11'>
              <div className='package-sub111'>
                <b>Good</b>
              </div>
              <div class="vl"></div>
              <div className='package-sub112'>
                <b>Opportunity</b>
              </div>
            </div>
            <div className='package-sub12'>
              <button id='btn-1'>Affordable</button>
              <button id='btn-2'>Price</button>
            </div>
            <div className='package-sub13'>
              <hr />
              <p>INDIVIDUAL PACKAGE</p>
              <hr />
            </div>
            <div className='bg-white package-sub14'>
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Free accomodagrey<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Free transporation<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Free health insurance<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Free regeneration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Flexible working hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Permanent residency<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp; Passport after 5 years<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  FrSalary on time<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Low cast of living<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  Legal employement<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp; Your right is protected<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'blue' }} /> &nbsp;  No company ban<br />
              <center> <button id='btn-3'>APPLY NOW</button></center>
            </div>
          </div>
          <div className='package-sub2 col-xl-4 '>
            <img src={malta} alt='malta' />
            <div className='package-sub11'>
              <div className='package-sub111'>
                <b>Good</b>
              </div>
              <div class="vl"></div>
              <div className='package-sub112'>
                <b>Opportunity</b>
              </div>
            </div>
            <div className='package-sub12'>
              <button id='btn-1'>Affordable</button>
              <button id='btn-2'>Price</button>
            </div>
            <div className='package-sub13'>
              <hr />
              <p>INDIVIDUAL PACKAGE</p>
              <hr />
            </div>
            <div className='bg-white package-sub14'>
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Free health insurance<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Free regeneration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Flexible working hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Ease to transporation<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Flexible working hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Attractive rental market<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp; Passport after 5 years<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  FrSalary on time<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Low cast of living<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  Legal employement<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp; Your right is protected<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'grey' }} /> &nbsp;  No company ban<br />
              <center> <button id='btn-4'>APPLY NOW</button></center>
            </div>
          </div>
          <div className='package-sub3 col-xl-4 '>
            <img src={czech} alt='czech' className='mb-2' />
            <div className='package-sub11'>
              <div className='package-sub111'>
                <b>Good</b>
              </div>
              <div class="vl"></div>
              <div className='package-sub112'>
                <b>Opportunity</b>
              </div>
            </div>
            <div className='package-sub12'>
              <button id='btn-1'>Affordable</button>
              <button id='btn-2'>Price</button>
            </div>
            <div className='package-sub13'>
              <hr />
              <p>INDIVIDUAL PACKAGE</p>
              <hr />
            </div>
            <div className='bg-white package-sub14'>
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Free health insurance<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Flexible regeneration hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Free regeration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Free regeneration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Ease of transporation<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Permanent residency<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp; Passport after 5 years<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  FrSalary on time<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Low cast of living<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Legal employement<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp; Your right is protected<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  No company ban<br />
              <center> <button id='btn-5'>APPLY NOW</button></center>
            </div>
          </div>
        </div>
        <div class="package-main2  ">
          <div className='package-sub1 col-xl-4 col-sm-12'>
            <img src={polands} alt='poland' className='mb-3' />
            <div className='package-sub11'>
              <div className='package-sub111'>
                <b>Good</b>
              </div>
              <div class="vl"></div>
              <div className='package-sub112'>
                <b>Opportunity</b>
              </div>
            </div>
            <div className='package-sub12'>
              <button id='btn-1'>Affordable</button>
              <button id='btn-2'>Price</button>
            </div>
            <div className='package-sub13'>
              <hr />
              <p>INDIVIDUAL PACKAGE</p>
              <hr />
            </div>
            <div className='bg-white package-sub14'>
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Free accomodagrey<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Free transporation<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Free health insurance<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Free regeneration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Flexible working hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Permanent residency<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp; Passport after 5 years<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  FrSalary on time<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Low cast of living<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  Legal employement<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp; Your right is protected<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'red' }} /> &nbsp;  No company ban<br />
              <center> <button id='btn-6'>APPLY NOW</button></center>
            </div>
          </div>
          <div className='package-sub2 col-xl-4 col-sm-12'>
            <img src={canada} alt='malta' />
            <div className='package-sub11'>
              <div className='package-sub111'>
                <b>Good</b>
              </div>
              <div class="vl"></div>
              <div className='package-sub112'>
                <b>Opportunity</b>
              </div>
            </div>
            <div className='package-sub12'>
              <button id='btn-1'>Affordable</button>
              <button id='btn-2'>Price</button>
            </div>
            <div className='package-sub13'>
              <hr />
              <p>INDIVIDUAL PACKAGE</p>
              <hr />
            </div>
            <div className='bg-white package-sub14'>
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Free health insurance<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Free regeneration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Flexible working hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Ease to transporation<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Flexible working hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Attractive rental market<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp; Passport after 5 years<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  FrSalary on time<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Low cast of living<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  Legal employement<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp; Your right is protected<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade className='text-danger' /> &nbsp;  No company ban<br />
              <center> <button id='btn-7'>APPLY NOW</button></center>
            </div>
          </div>
          <div className='package-sub3 col-xl-4 col-sm-12 '>
            <img src={uk} alt='UK' className='mb-2' />
            <div className='package-sub11'>
              <div className='package-sub111'>
                <b>Good</b>
              </div>
              <div class="vl"></div>
              <div className='package-sub112'>
                <b>Opportunity</b>
              </div>
            </div>
            <div className='package-sub12'>
              <button id='btn-1'>Affordable</button>
              <button id='btn-2'>Price</button>
            </div>
            <div className='package-sub13'>
              <hr />
              <p>INDIVIDUAL PACKAGE</p>
              <hr />
            </div>
            <div className='bg-white package-sub14'>
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Free health insurance<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Flexible regeneration hours<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Free regeration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Free regeneration meal<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Ease of transporation<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Permanent residency<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp; Passport after 5 years<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  FrSalary on time<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Low cast of living<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  Legal employement<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp; Your right is protected<br />
              &nbsp;&nbsp;&nbsp;&nbsp; <FontAwesomeIcon icon={faRightFromBracket} beatFade style={{ color: 'green' }} /> &nbsp;  No company ban<br />
              <center> <button id='btn-5'>APPLY NOW</button></center>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Packages
