import React from 'react'

function Services() {
   return (
      <>
         <div className='img-service'>
            <h1 className=' text-center mb-4 ' style={{ color: "yellow" }}>OUR SERVICES IN UAE</h1>
             <div className=' mb-2 row sub-service'>
               <div className='service-1 border border-light-subtle me-4 col-xl-3 col-sm-12' style={{ width: "280px", height: "200px" }}>
                  <i class="bi bi-person-vcard-fill" style={{ fontSize: "100px", margin: "90px", color: 'yellow' }}></i><br />
                  <b className='text-white' style={{ fontSize: "30px", margin: "60px" }}>Document <br />
                     <span style={{ margin: "70px" }}>Services</span>
                  </b>
               </div>
               <div className='service-1 border border-light-subtle me-4 col-xl-3 col-sm-12' style={{ width: "280px", height: "200px" }}>
                  <i class="bi bi-envelope-heart " style={{ fontSize: "100px", margin: "90px", color: "yellow" }}></i><br />
                  <b className='text-white' style={{ fontSize: "30px", margin: "30px" }}>Support <br />
                  <span style={{ margin: "30px", padding: "" }}>During</span> <br />
                  <span style={{ margin: "30px", padding: "" }}>the Process</span>
                  </b>
               </div>
               <div className='service-1 border border-light-subtle me-4 col-xl-3 col-sm-12' style={{ width: "280px", height: "200px" }}>
                  <i class="bi bi-person-workspace" style={{ fontSize: "100px", margin: "90px", color: "yellow" }}></i><br />
                  <b className='text-white' style={{ fontSize: "30px", margin: "60px" }}>Free <br />
                     <span style={{ margin: "60px" }}>consultancy</span>
                  </b>
               </div>
               <div className='service-1 border border-light-subtle me-4 col-xl-3 col-sm-12' style={{ width: "280px", height: "200px" }}>
                  <i class="bi bi-person-fill-check" style={{ fontSize: "100px", margin: "90px", color: "yellow" }}></i><br />
                  <b className='text-white' style={{ fontSize: "30px", margin: "20px" }}>comprehensive <br />
                     <span style={{ margin: "26px" }}>Visa Service</span>
                  </b>
               </div>
            </div>
         </div>
      </>
   )
}

export default Services
