import React from 'react'
import We_Working_Spl_Field from './We_Working_Spl_Field'

const Yes_Germany = () => {
  return (
    <>
    <p></p>
    {/* Section Start */}
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/study-group4.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>

            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <div className="sec-title mb-30">
                  <div className="sub-text style2">Yes About Germany</div>
                  <h2 className="title pb-38">
                    Bachelor and Master in Germany for Indian Students.
                  </h2>
                  <div className="desc pb-35">
                    When you plan to migrate abroad to work, invest, or settle,
                    you must first determine whether you can apply for a visa to enter that nation.
                    This can help you evaluate whether applying for a visa to migrate to a given nation is worth your time and effort.
                    This is where determining eligibility is crucial.</div>
                  <p className="margin-0 pb-15">
                    Germany's location at the heart of Europe has shaped its history both for good and bad.
                    It borders nine neighbors, more than any other European country.

                  </p>
                </div>
                <div className="btn-part">
                  <a className="readon learn-more contact-us" >Learn More</a>
                </div>
              </div>
            </div>
          </div>
          <p></p>
          <h2 className="title pb-38">
            Yes, Study About in Germany.
          </h2>
          <div className="desc pb-35">
            Studying in Germany opens doors to the global job market.
            EU's most populous country with 83 million residents,
            including 13% international students, it offers a diverse and welcoming environment.
            Furthermore, 7 German universities rank among the top 150 universities in the world.
            Education costs are also minimal, with most states requiring a small semester contribution,
            usually ranging from 100 to 350 euros, making it an affordable and prestigious destination for higher education.
          </div>
          <div className="desc pb-35">
            Each country has its own set of eligibility criteria. Countries such as Australia,
            Canada, Saskatchewan, Quebec, the United Kingdom, and Germany use a points-based
            system to determine whether applicants are eligible to live, work, or study in their country.
          </div>
        </div>
      </div>
      {/* Section End */}
      {/* Section Start */}
      <p></p>
      <p></p>
      <We_Working_Spl_Field />
      {/* Section End */}
    </>
  )
}

export default Yes_Germany