import React from 'react'

function Faq() {
  return (
    <>
     <div className='pb-5  faq-main mt-5' style={{backgroundColor:"#f7f7eb"}}>
      <h1 className='heading text-warning pt-4 ny'>FAQ</h1>
         <div className='row ms-2 '> 
            <div className='faq-1 ms-5 col-xl-6 me-5'>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp; + 1. What is DreamUp education and Immigration?
                </div>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp; + 2. Is DreamUp education and Immigration legitimate?
                </div>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp; +3. What is the process of application?
                </div>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp;   +4. What is the age linmit to apply.
                </div>
            </div> 
            <div className='faq-2 ms-5 col-xl-6'>
              <div className='border border-light-subtle pt-3 pb-3'>
                 &nbsp;&nbsp;  +5. How do I pay for my application process?
                </div>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp; +6. How long does it take to get a visa?
                </div>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp; +7. Where are DreamUp education and Immigration offices or locations?
                </div>
                <div className='border border-light-subtle pt-3 pb-3'>
                &nbsp;&nbsp; + 8. I'm outside the UAE, can I apply for my visa through DreamUp education and Immigration?
                </div>
            </div> 
         </div>
         </div>
    </>
  )
}

export default Faq
