import axios from 'axios';
import React, { useState } from 'react'


const Login = () => {
  // const [admin, setAdmin] = useState("");
  // const [emailId, setEmailId] = useState("");
  // const [password, setPassword] = useState("");

  //   const handleSubmit = async (event) => {
  //     event.setAdmin();
  //   const handleSubmit = async (event) => {
  //     event.setEmailId();
  //   const handleSubmit = async (event) => {
  //     event.setPassword();
        
  //   try {
  //     const formData = new FormData();
  //     formData.append("admin", admin);
  //     formData.append("emailId", emailId);
  //     formData.append("password", password);
     

  //     const response = await axios.post(
  //       "http://localhost:3002/register",
  //       formData,
  //       {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //         },
  //       }
  //     );
  //     setSuccessMessage("Registration successful");
  //     // console.log('Registration successful:', response.data);
  //   } catch (error) {
  //     setErrorMessage("Registration failed");
  //     // console.error('Registration failed:', error.response.data);
  //   }
  // };

  // const handleChange = (event) => {
  //   setAdmin(event.target.value);
  //   const handleChange = (event) => {
  //     setEmailId(event.target.value);
  //     const handleChange = (event) => {
  //       setPassword(event.target.value);
          
  // };

  return (
    <>
      <section class="bg-primary" >
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card shadow-5-strong" style={{ borderRadius: "1rem" }}>
                <div class="card-body p-5 text-center">
                  <h3 class="mb-5">Login Form</h3>

                  <form >
                    <select class="custom-select form-control mb-1"
                      // onChange={handleChange}
                      name="admin"
                      >
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                      <option value="Other">Other</option>
                    </select>

                    <div data-mdb-input-init class="form-outline mb-1">
                      <input type="text"
                        class="form-control form-control-sm"
                        placeholder='Enter Email'
                        id="emailid"
                        
                        // onChange={handleChange}
                      />
                    </div>

                    <div data-mdb-input-init class="form-outline mb-1">
                      <input
                        class="form-control form-control-sm"
                        placeholder='Enter Password'
                        type="password"
                        id="password"
                        // onChange={handleChange}
                      />
                    </div>
                    
                    <div data-mdb-input-init class="form-outline mb-1">
                      <input type="file"
                        id="password"
                        name='password'
                        accept='image/*'
                        class="form-control form-control-sm" />
                    </div>
                    <button data-mdb-button-init data-mdb-ripple-init class="btn btn-success btn-sm btn-block" type="submit">Login</button>
                   
                  </form>
                  {/* {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                  {successMessage && <p style={{ color: "green" }}>{successMessage}</p>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login
