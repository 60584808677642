import React from 'react'
import { Link } from 'react-router-dom'

const Directors = () => {
  return (
    <>
     <p></p>
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80">
               <div className="container">
                   <div className="row align-items-center">
                       <div className="col-lg-6 md-mb-50">
                           <div className="images">
                               <img src="img/director.jpg" alt="image" style={{ width: "100%" }} />
                           </div>
                       </div>

                       <div className="col-lg-6 pl-60 md-pl-15">
                           <div className="contact-wrap">
                               <div className="sec-title mb-30">
                                   <div className="sub-text style2">Director Mr. Ayaz</div>
                                   <h2 className="title pb-38">
                                     MR. AYAZ
                                   </h2>
                                   <div className="desc pb-35">
                                       Germany's central and southern regions have forested hills and mountains cut through by the Danube,
                                       Main, and Rhine river valleys. In the north, the landscape flattens out to a wide plain that stretches
                                       to the North Sea. Between these extremes, Germany is a country of incredible variety.
                                   </div>
                                   <p className="margin-0 pb-15">
                                       Germany's location at the heart of Europe has shaped its history both for good and bad.
                                       It borders nine neighbors, more than any other European country.
                                   </p>
                               </div>
                               <div className="btn-part">
                                   <Link className="readon learn-more contact-us" href="">Learn More</Link>
                               </div>
                           </div>
                       </div>
                   </div>
                   <p></p>
                   <h2 className="title pb-38">
                      Why Vocational training from Germany?
                   </h2>
                   <div className="desc pb-35">
                   Germany is increasing in popularity among students looking to study abroad, 
                   and for all its good reasons! The advantages of studying in Germany are numerous. 
                   The country's rich tradition, cultural diversity, bright and pleasant lifestyle, 
                   and globally recognized degrees guaranteeing exceptional employ-ability have added to its growing admiration. 
                   According to a survey, Indians make up the second-largest international student population attending German universities.
                   </div>
                   <p></p>
                   <div className="row align-items-center">
                       <div className="col-lg-6 pl-60 md-pl-15">
                           <div className="contact-wrap">
                               <div className="sec-title mb-30">
                                   <div className="sub-text style2">Director Mr. Jasmer</div>
                                   <h2 className="title pb-38">
                                      MR. JASMER
                                   </h2>
                                   <div className="desc pb-35">
                                       Germany's central and southern regions have forested hills and mountains cut through by the Danube,
                                       Main, and Rhine river valleys. In the north, the landscape flattens out to a wide plain that stretches
                                       to the North Sea. Between these extremes, Germany is a country of incredible variety.
                                   </div>
                                   <p className="margin-0 pb-15">
                                       Germany's location at the heart of Europe has shaped its history both for good and bad.
                                       It borders nine neighbors, more than any other European country.
                                   </p>
                               </div>
                               <div className="btn-part">
                                   <a className="readon learn-more contact-us" href="">Learn More</a>
                               </div>
                           </div>
                       </div>
                       <div className="col-lg-6 md-mb-50">
                           <div className="images">
                               <img src="img/director.jpg" alt="image" style={{ width: "100%" }} />
                           </div>
                       </div>
                   </div>
               </div>
           </div>
           {/* Section Start */}
    </>
  )
}

export default Directors