import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/pagination';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Cards() {
  return (
    <>
      <div style={{ backgroundColor: "#eeeeee", height: "600px" }} className='pb-5'>
        <h1 className='text-center mt-5 mb-3 pt-5 pb-4' style={{color:"#fccc1c"}}>OUR SUCCESS STORIES</h1>
        <div className='d-flex'>
          <Swiper
            slidesPerView={3}
            centeredSlides={true}
            spaceBetween={30}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiperrr"
          >
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart.jpeg" style={{width:"100%", height:"240px"}} alt="Card-image"  />
                <div class="card-body">
                  <h5 class="card-title">March Princess juanite</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart1.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Yousuf Atimen</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart2.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Sahil Vig</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart3.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Elena Montforte</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>

            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart4.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Maffy Cezina</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>

            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart5.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Joy Dev</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart6.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Harish</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart7.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Monika</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="card" style={{ width: "18rem;" }}>
                <img class="card-img-top" src="img/about/dreamup-cart8.jpeg" alt="Card-image" style={{width:"100%", height:"240px"}} />
                <div class="card-body">
                  <h5 class="card-title">Kriti</h5>
                  <p class="card-text">It was kind of very fast and I would like to
                    thank<br /> DreamUp Education and Immigration team for working tirelessly to ensure ...Readmore.</p>

                  <a class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  )
}

export default Cards
