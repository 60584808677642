import React from 'react';
import Home from "./IndexComponent/Home";
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Navbar from './IndexComponent/Navbar';
import "./App.css";
import Footer from './IndexComponent/Footer'
import About from './AboutComponent/About'
import Contacter from './ContactComponent/Contacter';
import WorkVisa from './IndexComponent/WorkVisa';
import Certificate from './IndexComponent/Certificate ';
import Gallery from './IndexComponent/Gallery';
import Directors from './IndexComponent/Directors';
import Yes_Germany from './AboutComponent/Yes_Germany';
import Achievements from './AboutComponent/Achievements';
import Career from './AboutComponent/Career';
import Login from './AuthenticationComponent/Login';
function App() {
  return (
    <>
       <BrowserRouter>
         <Navbar/>
          <Routes>
             <Route path='/' element={<Home/>}></Route>
             <Route path='/about' element={<About/>}></Route>
             <Route path='/contacter' element={<Contacter/>}></Route>
             <Route path='/workvisa' element={<WorkVisa/>}></Route>
             <Route path='/certificate' element={<Certificate/>}></Route>
             <Route path='/gallery' element={<Gallery/>}></Route>
             <Route path='/director' element={<Directors/>}></Route>
             <Route path='/yesgermany' element={<Yes_Germany/>}></Route>
             <Route path='/achievement' element={<Achievements/>}></Route>
             <Route path='/career' element={<Career/>}></Route>
             <Route path='/login' element={<Login/>}></Route>
             
          </Routes>
          <Footer/>
       </BrowserRouter>
    </>
  )
}

export default App
