import React from 'react'
import sfimg1 from "../image2/img4/8-YEARS-@4x.png";
import sfimg2 from "../image2/img4/VISA-GRANTED@4x-768x298.png";

function Safety() {
  return (
    <>
      <div className='container-fluid mt-4 ml-5'>
        <div className=' d-flex justify-content-center  row'>
          <div className='sf1 me-3 col-xl-3 col-sm-12'>
            <img src={sfimg1} alt='img' height={80} width={200} style={{ marginLeft: "10px" }} />
          </div>
          <div className='sf3 pb-4 me-3 col-xl-3 col-sm-12' style={{ width: "200px" }}>
            <b className='text-white font-weight h1 ms-5 '>1000+</b>
          </div>
          <div className='sf1 me-3 col-xl-3 col-sm-12'>
            <img src="img/dreamup-logo.png" alt='img' height={80} width={230} style={{ marginLeft: "4px" }} />
          </div>
          <div className='sf2  col-xl-3 col-sm-12'>
            <img src={sfimg2} alt='img' height={80} width={200} style={{ marginLeft: "6px" }} />
          </div>

        </div>
        <div className=' safar2 mt-4 img-thumbnail'>
          <h1 className='text-warning text-center'>FOR YOUR SAFETY</h1>
          <h4 className='bold text-center' style={{ color: "#f7c436" }}>Our consultants 
            do Not accept cash payments- the only acceptable method of payment <br /> is 
            bank transfer or deposit with below details :</h4>
          <div className='d-flex justify-content-center mt-5'>
            <div className='me-5'>
              <b>
              <p className='text-white' style={{ fontSize: "20px" }}>DreamUp Education and Immigration Pvt. Ltd.</p>
              <p className='text-white' style={{ fontSize: "20px" }}>Bank Name : HDFC Bank</p>
              <p className='text-white' style={{ fontSize: "20px" }}>Account Number : 50200096222178</p>
              <p className='text-white' style={{ fontSize: "20px" }}>IFSC : HDFC0000171</p>
              </b>
            </div>
            {/* <div>
              <p className='text-white' style={{ fontSize: "20px" }}>DreamUp Education and Immigration Pvt. Ltd.</p>
              <p className='text-white' style={{ fontSize: "20px" }}>Bank Name: HDFC Bank</p>
              <p className='text-white' style={{ fontSize: "20px" }}>Account Number: 50200096222178</p>
              <p className='text-white' style={{ fontSize: "20px" }}>IFSC: HDFC0000171</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}

export default Safety
