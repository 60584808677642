import React from 'react'

function Details() {
      return (
            <>
                  <div className='d-flex mt-5 container'>
                        <div style={{ backgroundColor: "yellow", width: "600px", height: "450px", borderRadius: "40px" }} className='me-5 ps-5 pt-5' >
                              <h1>Dubai </h1>
                              <h1>Head Office</h1>
                              <i class="bi bi-building-fill-add"></i>&nbsp;&nbsp;<b>1301, Boulevard Plaza, Tower 1 Downtown, Dubai, UAE - United Arab Emirates</b><br />
                              <i class="bi bi-phone-vibrate"></i>&nbsp;&nbsp;<b>0180-4024575 - Customer Services Department</b><br />
                              <i class="bi bi-whatsapp"></i>&nbsp;&nbsp;<b>+91 8533929212 - WhatsApp & call to customer Services Department</b><br />
                              <i class="bi bi-chat-left-text"></i>&nbsp;&nbsp;<b>dreamupimmigration@gmail.com</b> <br />
                              <i class="bi bi-alarm"></i>&nbsp;&nbsp;<b>7 days a week, 24 hours</b>
                        </div>
                        <div style={{ backgroundColor: "black", width: "600px", height: "450px", borderRadius: "40px" }} className='ms-5 ps-4 pt-4'>
                              <h1 className='text-white'>India</h1>
                              <h1 className='text-white'>Branch Office</h1>
                              <i class="bi bi-building-fill-add" style={{ color: "white" }}></i>&nbsp;&nbsp;<b className='text-white'>Below Lotus Hospital, Sanoli Road, Panipat, Haryana,
                              India - 132103</b><br />
                              <i class="bi bi-phone-vibrate" style={{ color: "white" }}></i>&nbsp;&nbsp;<b className='text-white'>0180-4024575 - Customer Services Department</b><br />
                              <i class="bi bi-whatsapp" style={{ color: "white" }}></i>&nbsp;&nbsp;<b className='text-white'>+91 8533929212 - WhatsApp & call to customer Services Department</b><br />
                              <i class="bi bi-chat-left-text" style={{ color: "white" }}></i>&nbsp;&nbsp;<b className='text-white'>dreamupimmigration@gmail.com</b> <br />
                              <i class="bi bi-alarm" style={{ color: "white" }}></i>&nbsp;&nbsp;<b className='text-white'>6 days a week, 10:00am to 06:00pm</b>
                        </div>
                  </div>

                  <div className='mt-3 d-flex justify-content-evenly' style={{ backgroundColor: "grey", height: "300px" }}>
                        <div style={{ border: "5px solid yellow" }} className='mt-5 pt-4 mb-5 ps-5 pe-5'>
                              <i class="bi bi-telephone" style={{ color: "white", fontSize: "2rem" }} ></i>
                              <h2 style={{ color: "white", height: "30px" }}>Call</h2><br />
                              <h2 style={{ color: "white" }}>+91-8533929212</h2>
                        </div>
                        <div style={{ border: "5px solid yellow" }} className='mt-5 pt-4 mb-5 ps-5 pe-5'>
                              <i class="bi bi-envelope" style={{ color: "white", fontSize: "2rem" }}></i>
                              <h2 style={{ color: "white", height: "30px" }}>Email</h2><br />
                              <h2 style={{ color: "white" }}>dreamupimmigration@gmail.com</h2>
                        </div>
                  </div>
            </>
      )
}

export default Details
