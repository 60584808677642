import React from 'react'
import { Link } from 'react-router-dom'

const Gallery = () => {
  return (
    <>
    <div>
      {/* Section Start */}
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 mt-4 md-pb-80">
        <div className="container">
          <h2 className="title pb-38" style={{color:"#f7be39"}}>
            Our Gallery
          </h2>
          <div className="desc pb-35">
            Studying in Germany opens doors to the global job market.
            EU's most populous country with 83 million residents,
            including 13% international students, it offers a diverse and welcoming environment.
            Furthermore, 7 German universities rank among the top 150 universities in the world.
            Education costs are also minimal, with most states requiring a small semester contribution,
            usually ranging from 100 to 350 euros, making it an affordable and prestigious destination for higher education.
            <a className="learn-more " href="">Learn More</a>
          </div>
        </div>
      </div>
      {/* Section End */}
      {/* Section Start */}
      <div className="container-fluid py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
              <div className="section-title bg-light rounded h-100 p-4">
                <h3 className="position-relative d-inline-block  text-uppercase " style={{color:"#f7be39"}}>Our Galleries</h3>
                <h2 className="display-6 mb-4">We Offering The Best Quality in DreamUp Education and Immigration.</h2>
                <Link to="/contact" className="btn btn-primary py-3 px-5">Contact Us</Link>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery1.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery2.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery3.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery4.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery5.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery6.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery7.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery8.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery9.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery10.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery11.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery12.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery13.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery14.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery15.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery16.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery17.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery18.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery19.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery20.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery21.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery22.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery23.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery24.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery25.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery26.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery27.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery28.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery29.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery30.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery31.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery32.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery33.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery34.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery35.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery36.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery37.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery38.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery39.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery40.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery41.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery42.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery43.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery44.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery45.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery46.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery47.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery48.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery49.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery50.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery51.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery52.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery53.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery54.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery55.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery56.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery57.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery58.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery59.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery60.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery61.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery62.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery63.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery64.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery65.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery66.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery67.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery68.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery69.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery70.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery71.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery72.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery73.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery74.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery75.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery76.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery77.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery78.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery79.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery80.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery81.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery82.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery83.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery84.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery85.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery86.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery87.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery88.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery89.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery91.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery92.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery93.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery94.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery95.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery96.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery97.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery98.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery99.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery100.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery101.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery102.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery103.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery104.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery105.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery106.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery107.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery108.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
           
            <div className='div-color1'><h3 style={{color:"white"}}>Offer Letter of Poland...</h3></div>
          
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery109.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery110.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery111.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery112.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery113.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery114.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery115.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery116.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery117.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/dreamup101.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/dreamup102.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-1.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-2.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-3.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-4.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-5.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-6.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-7.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-8.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/galleryimage/gallery-dreamup-9.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
      {/* Service End */}
    </div>
  </>
  )
}

export default Gallery