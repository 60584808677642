import React from 'react'
import { Link } from 'react-router-dom'
import We_Working_Spl_Field from './We_Working_Spl_Field'

const Career = () => {
  return (
    <>
      {/* Section Start */}
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center mt-1">
           
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <div className="sec-title mb-30">
                 <div className="sub-text style2">Career with Us</div>
                  <h1 className="title pb-38"> Career with Us </h1>
                  <div className="desc pb-35">
                  Career With Us Consultancy services is supported by dynamic rung of a consultants. 
                  We introduce ourselves as an organization committed to establish and 
                  maintain new standards in Human Resource Consulting.
                  <Link> read more...</Link></div>
                </div>
                <div className="btn-part mt-4">
                  <Link className="readon learn-more contact-us" >Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/dreamup-career.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section End */}
      
      {/* Section Start */}
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80 mt-3">
        <div className="container">
          <h1 className="title pb-38"> Our Career </h1>
          <div className="desc pb-35">
            Germany is increasing in popularity among students looking to study abroad,
            and for all its good reasons! The advantages of studying in Germany are numerous.
            The country's rich tradition, cultural diversity, bright and pleasant lifestyle,
            and globally recognized degrees guaranteeing exceptional employ-ability have added to its growing admiration.
            According to a survey, Indians make up the second-largest international student population attending German universities.
            <Link> read more...</Link>
          </div>
        </div>
      </div>
      {/* Section End */}
      {/* Section Start */}
      <p></p>
      <We_Working_Spl_Field />
      {/* Section End */}
    </>
  )
}

export default Career