import React, { useState } from 'react'
import Details from './Details'
import Center from './Center'
import { Link } from 'react-router-dom'

function Contacter() {

  const [formData, setFormData] = useState({
    firstName: '',
    // email: '',
    // password: '',
    // Add other fields as necessary
  });
  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://localhost:8000/api/v1/contact/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Form submitted successfully:', result);
        alert(" Successfully Created Contact Details. ")
      } else {
        console.error('Error submitting form:', response.statusText);
      }
    } catch (error) {
      console.error('Network error:', error);
    }
  };

  return (
    <>
      <div className='container d-flex mt-3' style={{backgroundColor:"#f7f7eb", borderRadius:"20px"}}>
        <div className='me-5'>
          <h1 className='text-warning mb-3'>WHATS  MAKES US <br /> UNIQUE</h1>
          <p className='mb-3'>We do not only want you to reach your new country safely, but also to<br />
            have comprehensive support throughout your whole stay there, what <br />
            includes i.e.:</p>
          <i class="bi bi-arrow-right" ></i>&nbsp;<span class='span1'> Assistance in applying for permanent residence card</span> <br />
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Complex support for acquiring citizenship </span><br />
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Practical information on where you will reside, such as public transport,<br />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; services, entertainment facilities</span><br />
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Support your relatives' arrival and guarantee their permanent residence <br /></span>
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Information on child and social welfare schemes </span><br />
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Guidance for renting your own property </span><br />
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Guidance for admission in language or driving course</span> <br />
          <i class="bi bi-arrow-right" ></i>&nbsp;<span className='span1'>Assistance and advice on opening a bank account </span><br />
          <p className='mt-3'>DreamUp Education and Immigration aims to support you expeditiously for you to have a higher<br />
            quality of life through our various opportunities in several destinations<br />
            across Europe and Canada. </p>
          <p className='mt-3 mb-2'>We are there for you from the beginning of the process till the time you <br />
            land and start work in your dream destination country.  DreamUp Education and Immigration - We <br />
            are for people!</p>
          <Link  class="btn btn-warning center">Read more...</Link>
        </div>
        <div>
          <center> <h1 className='text-warning'>GET IN TOUCH</h1></center>
          <p> Our consultant will give you a call with all the necessary
            information needed for Your travel to <b> Many Countries</b>. <br />
             Please fill out the <b className='tcc1'> Contact Form : </b> </p>
          <hr />
          <form onSubmit={handleSubmit}>
            <div class="mb-3">
              <label> Full Name : </label>
              <input type="text" onChange={handleChange} name='fullName' placeholder='full name...' class="form-control" id="" />
            </div>
            <div class="mb-3">
              <label> Email Id : </label>
              <input type="mail" onChange={handleChange} name='email' placeholder='e-mail' class="form-control" id="" />
            </div>
             <div class="mb-3">
             <label> Password : </label>
              <input type="password" onChange={handleChange} name='password' placeholder='password' class="form-control" id="" />
            </div>
            <div class="mb-3">
              <label> Mobile : </label>
              <input type="number" onChange={handleChange} name='mobile' placeholder='mobile' class="form-control" id="" />
            </div>
            <div class="mb-3">
              <label> Enter Position : </label>
              <input type="text" onChange={handleChange} name='position' placeholder='position' class="form-control" id="" />
            </div>
            <div>
              <label> Choose Country : </label>
              <select name='chooseCountry' onChange={handleChange} class="form-select" aria-label="Default select example">
                <option selected>choose country...</option>
                <option value="Poland">Poland</option>
                <option value="Czech">Czech</option>
                <option value="Canada">Canada</option>
                <option value="Malta">Malta</option>
                <option value="Germany">Germany</option>
              </select>
            </div>
            <div className='mt-3'>
              <label> Reference By : </label>
              <select name='referenceBy' onChange={handleChange} class="form-select" aria-label="Default select example">
                <option selected>reference by...</option>
                <option value="Social media">Social media</option>
                <option value="Google">Google</option>
                <option value="Referred By">Referred By</option>
              </select>
              <div className='mt-3'>
                <label> Agent / Person Name : </label>
                <input type="text" onChange={handleChange} name='agentName' placeholder='enter agent name' class="form-control" id="" />
                </div>
            </div>
            <p></p>
            <button type='submit' class="btn btn-warning center me-5">Apply Now</button>
            <a href='' class="btn btn-warning center ">Re-Entry Now</a>
          </form>
        </div>
      </div>
      <Details />
      <Center />
    </>
  )
}

export default Contacter
