import React from 'react'

function Workin() {
  return (
    <div className='container work-main row'>
      <h1 className='text-warning mt-4 text-center '>I WANT TO LIVE & WORK IN</h1>
      <div className='img-country1 col-xl-12 col-sm-12'>
      </div>
      <div className='img-country2 col-xl-12 col-sm-12'>
      </div>
      <div className='img-country3 col-xl-12 col-sm-12'>
      </div>
     
    </div>
  )
}

export default Workin
