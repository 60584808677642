import React from 'react'
import { CgEditBlackPoint } from "react-icons/cg";
import { Link } from 'react-router-dom';
import { TransformWrapper, TranformComponent } from "react-zoom-pan-pinch";

const Certificate  = () => {
  return (
    <>
    <p></p>
    <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          {/* Section Start */}
          <div className="sec-title mb-30">
            <div className="sub-text" style={{color:"#f7be39"}}>Companies Certificates</div>
            <div>
              <h2 className=" title pb-38" style={{color:"#f7be39"}}>
              Companies Certificate : 
              </h2>
              <div className="desc pb-35">
              Businesses issue certificates to shareholders, members or partners in 
              order to provide proof of ownership. This proof is typically provided in 
              the form of a certificate:
              <p></p>
             <p>
                <CgEditBlackPoint color='#75222f' size={"20px"} /> Stock certificates for corporations
                <br />
                <CgEditBlackPoint color='#75222f' size={"20px"} /> Membership certificates for LLCs
                <br />
                <CgEditBlackPoint color='#75222f' size={"20px"} /> Partnership certificates for LPs and LLPs
              </p>
              </div>
            </div>
          </div>
          {/* Section End */}
          {/* Section Start */}
          <div className="row g-5">
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
              <div className="section-title bg-light rounded h-100 p-4 ">
                <h3 className="position-relative d-inline-block text-uppercase" style={{color:"#f7be39"}}>Certificates</h3>
                <h2 className="display-6 mb-4">DreamUp Education <br /> and Immigration Company's Certificates!
                </h2>
                <Link to="/contact" className="btn btn-primary py-3 px-5">Contact Us</Link>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate1.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate2.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate3.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate4.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate5.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate6.jpeg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate7.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate8.png" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate9.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">

                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
              <div className="team-item">
                <div className="position-relative rounded-top" style={{ zIndex: 1 }}>
                  <img className="img-fluid rounded-top w-100" src="img/certificate/dreamup-certificate10.jpg" alt="image" />
                  <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                  </div>
                </div>
                <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                  <h4 className="mb-2">DreamUp </h4>
                  <p className="text-primary mb-0">DreamUp Education and Immigration Panipat, Haryana, India</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Certificate 