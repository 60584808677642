import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <>
    <div className='text-bg-dark mt-5 pt-2 main-foot'>
      <div className='foot-main ms-5 row'>
        <div className='foot-1 col-xl-3 col-sm-12'>
         <h2 className='text-warning fz'>8  
         <span className='fs-1'>
             YEARS
         </span>
         </h2>
         <p>OF TRUSTED PARTNERSHIP</p>
         <h5 className='text-warning'>DreamUP Education</h5>
         <p>We are for people.</p>
         <hr className='width:30' style={{width:250}}/>
         <p>We have over 8 years of experience,<br/>
       1000 Visas issued to date, more than 140
       positions to apply.</p>
        </div>
        <div className='foot-1 mt-4 col-xl-1 col-sm-12'>
        <h2 className='text-warning'>Quick Link</h2>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;Terms & Condition<br/> 
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;FAQs<br/>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;Privacy Policy<br/>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;News<br/>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;About Us<br/>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;Contact Us<br/>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;Career Destination<br/>
        <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i> &nbsp;&nbsp;Track Your Application
       </div>
       <div className='foot-3 mt-4 col-xl-3 col-sm-12'>
           <h2 className='text-warning'>News Letters</h2>
           <p>Subscribe to join our latest 37.1k followers</p>
           <p>Our Web Site Name : www.dreamupeducation.com </p>
           
           <Link target='_blank' to="https://www.dreamupeducation.com/" class="btn btn-warning center"> Go to our Wesite </Link>
          
       </div>
      <div className='foot-4    col-xl-3 col-sm-12'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.4439012585567!2d76.97414877420265!3d29.386567749443074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390dda5a88e69ffd%3A0x74adc5c48d693675!2sLotus%20Hospital!5e0!3m2!1sen!2sin!4v1719985637240!5m2!1sen!2sin" id='iframe' ></iframe>
      </div>
      </div>
      <hr/>
      <span className='text-warning ms-5 me-5' >Follow Us</span>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i>&nbsp;&nbsp;Facebook&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i>&nbsp;&nbsp;Instagram&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i>&nbsp;&nbsp;Twitter&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i>&nbsp;&nbsp;Linkedin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <i class="bi bi-arrow-right-circle" style={{color: "#FACB08"}}></i>&nbsp;&nbsp;Youtube&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      </div> 
    </>
  )
}

export default Footer
