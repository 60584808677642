import React from 'react'
import legal from "../image2/img5/Icons-2015_Legal-employment.png";
import respect from "../image2/img5/Icons-2015_Respect-for-your-rights-.png";
import salery from "../image2/img5/Icons-2015_Salary-on-time.png";
import payment from "../image2/img5/Icons-2015_Hourly-payment.png";
import accomb from "../image2/img5/Icons-2015_Free-Accommodation-.png";

function Value() {
  return (
    <>
      <div className='valueimg '>
        <h1 className='text-warning text-center ' id='valueh1'>OUR VALUES</h1>
       
        <div className='d-flex container mt-5 me-5 ' >
          <div className='me-5'>
            <img src={legal} alt='img' />
            <p className='text-white'>Legal employment</p>
          </div>
          <div className='me-5'>
            <img src={respect} alt='img' />
            <p className='text-white'>Respect for your rights - no <br />
              company ban, no holding of <br /> passport</p>
          </div>
          <div className='me-5'>
            <img src={salery} alt='img' />
            <p className='text-white'>Salary always on time</p>
          </div>
          <div className='me-5'>
            <img src={payment} alt='img' />
            <p className='text-white'>Hourly payment - no <br />
              limitation of working hours</p>
          </div>
          <div className='me-5'>
            <img src={accomb} alt='img' />
            <p className='text-white'>Free accommodation,<br />
              transportation, health <br />
              insurance, regeneration meal</p>
          </div>
        </div>
      </div>
      
      <div className=' d-flex w-100 mt-5'>
        <div style={{ backgroundColor: "yellow", width: "800px", paddingTop: "20px" }} className='ps-5'>
          <h1 className='mb-5'>We are an equal opportunity employer</h1>
          DreamUp Education and Immigration is an equal opportunity employer and makes all employment decisions based only on the<br />
          basis of your qualifications - without regard to Your race, colour of skin, religion, sex, gender<br />
          identity or national origin.
        </div>
        <div style={{ backgroundColor: "black", width: "800px", paddingTop: "20px" }} className='ps-5'>
          <h1 style={{ color: "white" }} className='mb-4'>You want to work with us? Let us help <br />
            with the processing of visa!</h1>
          <p style={{ color: "white" }} className='mb-4'>Our HR team together with the legal departments in Europe/Canada and UAE ensures you the<br />
            highest standard of service and is in regular contact with you, ensuring that all your documents<br />
            meet all formal and legal requirements.</p>

          <p style={{ color: "white" }}>Through our directness (we are not the agency!) we provide you our assistance during the<br />
            processing of the documents necessary for visa to start your career in Europe and Canada.</p>
        </div>
      </div>
    </>
  )
}

export default Value
