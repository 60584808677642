import React from 'react'
import { CgEditBlackPoint } from 'react-icons/cg'

const WorkVisa = () => {
  return (
    <>
      <p></p>
      <div className="rs-about bg4 pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          {/* Section Start */}
          <div className="sec-title mb-30">
            <div className="sub-text style2">JOBS in Poland and Croatia</div>
            <div>
              <h2 className=" title pb-38" style={{color : "#fac227"}}>
                JOBS in Poland and Croatia
              </h2>
              <div className="desc pb-35">
                With the help of our team and your talent, you can find jobs in Poland to
                start building the next step in your career. We provide different job
                opportunities for your chosen destination.
              </div>
            </div>
          </div>
          {/* Section End */}
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job1.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Picking Up Boxes </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5500 PLN <br />
                  <strong> PER HOUR RATE : </strong> 15 PLN per 1 hour - if you work monthly, less than 200 hours 16 PLN per 1 hour - if you work monthly, more than 200 hours <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00am to 14:00pm (10 hours) 14:00pm to 00:00am (10 hours) Max. working hours per day: 12 - 14 hours <br />
                  <strong> DESCRIPTION : </strong> There are boxes with various meat products <br />
                  (bones, ham, lard, ribs, etc.) you are assigned to
                  put those boxes in container One of the products is
                  assigned to the employee, you need to remove the box
                  from the line and pour it into a special container.
                  After filling, the container is transported across
                  the plant by hand. The weight of the box is from 15
                  to 30 kg.
                </div>
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Meat Packer </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years <br />
                  <strong> CITY : </strong> Szczecin, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5300 PLN <br />
                  <strong> PER HOUR RATE : </strong> 13 zł - packaging on canned lines - 13.50 zł -
                  packaging on the line of meat products (white side) - 14.50 zł -
                  work on knives, cutting barrel (orange side) <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  WORKING HOURS 10-16 working hours, different schedule 230-260 hours / monthly <br />
                  <strong> DESCRIPTION :</strong> packaging on the line meat products, (ribs, barrels, meat, sausages, frankfurters, canned food). <br />
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job2.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job3.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Forklift Operator </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5500 PLN <br />
                  <strong> PER HOUR RATE : </strong> 15 PLN per 1 hour - if you work monthly, less than 200 hours 16 PLN per 1 hour - if you work monthly, more than 200 hours <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00am to 14:00pm (10 hours) 14:00pm to 00:00am (10 hours) Max. working hours per day: 12 - 14 hours <br />
                  <strong> DESCRIPTION : </strong> There are boxes with various meat products <br />
                  (bones, ham, lard, ribs, etc.) you are assigned to
                  put those boxes in container One of the products is
                  assigned to the employee, you need to remove the box
                  from the line and pour it into a special container.
                  After filling, the container is transported across
                  the plant by hand. The weight of the box is from 15
                  to 30 kg.
                </div>
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Waiter/Waitress </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5500 PLN <br />
                  <strong> PER HOUR RATE : </strong> 15 PLN per 1 hour - if you work monthly, less than 200 hours 16 PLN per 1 hour - if you work monthly, more than 200 hours <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00am to 14:00pm (10 hours) 14:00pm to 00:00am (10 hours) Max. working hours per day: 12 - 14 hours <br />
                  <strong> DESCRIPTION : </strong> There are boxes with various meat products
                  (bones, ham, lard, ribs, etc.) you are assigned to
                  put those boxes in container One of the products is
                  assigned to the employee, you need to remove the box
                  from the line and pour it into a special container.
                  After filling, the container is transported across
                  the plant by hand. The weight of the box is from 15
                  to 30 kg.
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job4.webp" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job5.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Room Cleaner </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5500 PLN <br />
                  <strong> PER HOUR RATE : </strong> 15 PLN per 1 hour - if you work monthly, less than 200 hours 16 PLN per 1 hour - if you work monthly, more than 200 hours <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00am to 14:00pm (10 hours) 14:00pm to 00:00am (10 hours) Max. working hours per day: 12 - 14 hours <br />
                  <strong> DESCRIPTION : </strong> There are boxes with various meat products <br />
                  (bones, ham, lard, ribs, etc.) you are assigned to
                  put those boxes in container One of the products is
                  assigned to the employee, you need to remove the box
                  from the line and pour it into a special container.
                  After filling, the container is transported across
                  the plant by hand. The weight of the box is from 15
                  to 30 kg.
                </div>
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Package Scanner </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5500 PLN <br />
                  <strong> PER HOUR RATE : </strong> 15 PLN per 1 hour - if you work monthly, less than 200 hours 16 PLN per 1 hour - if you work monthly, more than 200 hours <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00am to 14:00pm (10 hours) 14:00pm to 00:00am (10 hours) Max. working hours per day: 12 - 14 hours <br />
                  <strong> DESCRIPTION : </strong> There are boxes with various meat products
                  (bones, ham, lard, ribs, etc.) you are assigned to
                  put those boxes in container One of the products is
                  assigned to the employee, you need to remove the box
                  from the line and pour it into a special container.
                  After filling, the container is transported across
                  the plant by hand. The weight of the box is from 15
                  to 30 kg.
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job6.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job7.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Cheff </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5500 PLN <br />
                  <strong> PER HOUR RATE : </strong> 15 PLN per 1 hour - if you work monthly, less than 200 hours 16 PLN per 1 hour - if you work monthly, more than 200 hours <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00am to 14:00pm (10 hours) 14:00pm to 00:00am (10 hours) Max. working hours per day: 12 - 14 hours <br />
                  <strong> DESCRIPTION : </strong> There are boxes with various meat products <br />
                  (bones, ham, lard, ribs, etc.) you are assigned to
                  put those boxes in container One of the products is
                  assigned to the employee, you need to remove the box
                  from the line and pour it into a special container.
                  After filling, the container is transported across
                  the plant by hand. The weight of the box is from 15
                  to 30 kg.
                </div>
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Washing Boxes Worker </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Kutno, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 4200 PLN <br />
                  <strong> PER HOUR RATE : </strong> 14 PLN per 1 hour <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 04:00 am to 14:00 pm (10 hours) 14:00 pm to 00:00
                  am (10 hours) 00:00 am to 06:00 am (10 hours) or 2 work shift of 12 hours can be a
                  different schedule for married couples <br />
                  <strong> DESCRIPTION : </strong> To put empty boxes on the special washing machine
                  (the machine washes with warm water, with a small amount of chemicals). Control the
                  quality of cleaning and lay out the boxes.
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job8.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job9.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Fresh Bacon Packer </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Szczecin, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5000 PLN <br />
                  <strong> PER HOUR RATE : </strong> 13.50 zł - packaging on the line fresh bacon (white side) <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 10-16 working hours, different schedule 230-260 hours / monthly <br />
                  <strong> DESCRIPTION : </strong> packaging on the line fresh bacon.
                </div>
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Canned Food Packer </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Szczecin, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 4700 PLN or AED 3000 - 4000 <br />
                  <strong> PER HOUR RATE : </strong> 14 PLN per 1 hour <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 10-16 working hours, different schedule 230-360 hours / monthly <br />
                  <strong> DESCRIPTION : </strong> packaging on the line canned food
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job10.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job11.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Fresh Bacon Packer </h3>
                <div className="desc pb-35">
                  <strong> GENDER : </strong> men, women, couples under 55 years old <br />
                  <strong> CITY : </strong> Szczecin, Poland <br />
                  <strong> SALARY : </strong> 3000 PLN - 5000 PLN <br />
                  <strong> PER HOUR RATE : </strong> 13, 50 zł - packaging on the line smoked bacon (white side) <br />
                  <strong> SCHEDULE : </strong> Mon - Fri, sometimes Sat. <br />
                  <strong> WORKING HOURS : </strong> 10-16 working hours, different schedule 230-260 hours / monthly <br />
                  <strong> DESCRIPTION : </strong> packaging on the line smoked bacon
                  <strong> ADVANCE PART SALARY : </strong> In the first month of work you can request 200 zł advance after 7
                  working days 200 zł after 14 working days 200 zł after 21 working days.
                </div>
              </div>
            </div>
          </div>
          {/* Section End */}
          <p></p>
          <div className='div-color'></div>
          {/* Section Start */}
          <p></p>
          <div className="row align-items-center">
            <div className="col-lg-6 pl-60 md-pl-15">
              <div className="contact-wrap">
                <h3 className="title pb-38 font-color1">
                  <CgEditBlackPoint color='#75222f' /> Truck Driver </h3>
                <div className="desc pb-35">
                  <strong> Job Position :</strong> C+E Drivers (Truck Driver)<br />
                  <strong> Experience Required :</strong> Minimum 2 years, of experience in trailer driving
                  International Driving Permit (IDP) Required <br />
                  <strong> Accommodation :</strong> Free Accommodation Provided by the Company <br />
                  <strong> Salary Offered :</strong> 10,000 zl per month / INR 205,000 <br />
                  <strong> Documentation Requirements (After Visa) :<br /> 1 - Code 95 : </strong>
                  A mandatory qualification ensuring drivers possess the necessary skills
                  and knowledge for safe and efficient driving.<br />
                  <strong> 2 - Tachograph Card :</strong> Essential for monitoring and recording a driver's
                  activities, including driving time, rest periods, and breaks.<br />
                  <strong> Processing Time :</strong> The processing time for completing the necessary
                  documentation, including Code 95 and Tachograph card, is estimated to be between 90 to 120 days.
                  Additionally, we are pleased to inform you that accommodation will be provided by the company.<br />
                  <strong> Initiating Process - Required Documents :</strong><br />
                  1. Scanned Copy of All Pages of Passport.<br />
                  2. CV (Curriculum Vitae).<br />
                  3. Experience Certificate: Candidates with GCC experience are particularly
                  encouraged to apply (In pdf format).
                  We look forward to welcoming qualified and dedicated C+E Drivers to our team,
                  offering competitive compensation, a strong commitment to safety, and
                  opportunities for professional growth.
                </div>
              </div>
            </div>
            <div className="col-lg-6 md-mb-50">
              <div className="images">
                <img src="img/polanjobsimage/dreamup-job12.jpg" alt="image" style={{ width: "100%" }} />
              </div>
            </div>
          </div>
          {/* Section End */}

        </div>
      </div>
    </>
  )
}

export default WorkVisa