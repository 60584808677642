import React from 'react'

const We_Working_Spl_Field = () => {
  return (
     <>
      <div className="rs-industry pt-120 pb-120 md-pt-80 md-pb-80">
            <p></p>
            <div className="container">
              <div className="text-center sec-title mb-30">
                <span className="sub-text">We are working specific field.</span>
                <h2 className="title pb-38" style={{color:"#fac227"}}>
                  Best Solutions, For All Organizations
                </h2>
              </div>
              <div className="all-services">
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dreamup-2.jpg" style={{ width: "100%" }} alt="germany img" />
                      
                    </div>
                    <div className="services-text ">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Master's in Germany</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="/img/g1.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Bachelor's in Canada</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dreamup-1.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>German Lang Classes</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dreamup-3.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Vocational in Ireland</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dreamup-4.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title  pb-38" style={{color:"#cf9904"}}>Nursing in Newzealand</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dreamup-5.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Job Consultancy</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dream-up-6.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Visa Consultation</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dream-up-7.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Ielts</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dream-up-8.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Immigration</h4>
                    </div>
                  </div>
                </div>
                <div className="services-item">
                  <div className="services-wrap">
                    <div className="services-icon">
                      <img src="img/dream-up-9.jpg" style={{ width: "100%" }} alt="germany img" />
                    </div>
                    <div className="services-text">
                      <h4 className="title pb-38" style={{color:"#cf9904"}}>Testimonial</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
     </>
  )
}

export default We_Working_Spl_Field